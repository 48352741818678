@charset "utf-8";
/* CSS Document */

/******************************

COLOR PALETTE




[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Home
	3.1 Currency
	3.2 Language
	3.3 Account
	3.4 Main Navigation
	3.5 Hamburger Menu
	3.6 Logo
	3.7 Main Slider
4. Banner
5. New Arrivals
	5.1 New Arrivals Sorting
	5.2 New Arrivals Products Grid
6. Deal of the week
7. Best Sellers
	7.1 Slider Nav
8. Benefit
9. Blogs
10. Newsletter
11. Footer



******************************/

/***********
1. Fonts
***********/

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,900');

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
}
body
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 23px;
	font-weight: 400;
	background: #FFFFFF;
	color: #1e1e27;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 1.7;
	font-weight: 500;
	color: #989898;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 2px #fde0db;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FF6347;
	background: #fde0db;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: #fde0db;
	color: #FF6347;
}
p::selection
{
	background: #fde0db;
}
h1{font-size: 72px;}
h2{font-size: 40px;}
h3{font-size: 28px;}
h4{font-size: 24px;}
h5{font-size: 16px;}
h6{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	color: #282828;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection
{

}
::-webkit-input-placeholder
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
:-moz-placeholder /* older Firefox*/
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
::-moz-placeholder /* Firefox 19+ */
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
:-ms-input-placeholder
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
::input-placeholder
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}

.top-banner{
    line-height: 50px;
    font-size: 13px;
    color: #ffffff;
    text-transform: uppercase;
	text-align: center;
	width: 43%;
}

.icon-phone{
	width: 20px;
	height: 20px;
	margin: 10px;
}

.icon-big{
	width: 39px;
}
.icon-big-whatsapp{
	width: 47px;
    margin-top: -4px;
    margin-left: 20px;
}

.margin-left-23{
	margin-left: 23px;
}

.margin-left-100{
	margin-left: 100px;
}

.element-active{
	color: #e99c2e !important;
}
.side-element-active{
	color:#e99c2e !important;
}

/*********************************
3. Home
*********************************/

.header
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #FFFFFF;
	z-index: 10;
}
.top_nav
{
	width: 100%;
	height: 50px;
	background: #1e1e27;
}
.top_nav_left
{
	height: 50px;
	line-height: 50px;
	font-size: 13px;
	color: #ffffff;
	text-transform: uppercase;
}


.header-info
{
	display: inline-block;
	position: relative;
	min-width: 50px;
	/* border-right: solid 1px #33333b; */
	padding-right: 20px;
	padding-left: 20px;
	text-align: center;
	vertical-align: middle;
	background: #1e1e27;
}
.header-info > a
{
	display: block;
	color: #ffffff;
	font-weight: 400;
	height: 50px;
	line-height: 50px;
	font-size: 13px;
}
.header-info > a > i
{
	margin-left: 8px;
}
.header-info:hover
{
	visibility: visible;
	opacity: 1;
	top: 100%;
}
.header-info > a > img{
	width : 20px;
	height : 20px;
	margin-right: 5px;
}


/*********************************
3.4 Main Navigation
*********************************/

.main_nav_container
{
	width: 100%;
	background: #FFFFFF;
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
}
.navbar
{
	left:165px;
	width: 80%;
	flex-direction: column;
	justify-content: space-evenly;
	min-height: 100px;
	padding-left: 0px;
	padding-right: 0px;
}
/* .header_area{
	width:300px !important;
	justify-content: left !important;
	align-items: start !important;
} */

.header_area .search-area  {
    position: relative;
    z-index: 2;
    height: 85px;
	width: 340px;
	right:164px;
	border: none;
}
    .header_area .search-area input {
      border: none;
      background-color: #ffffff;
      height: 35px;
      color: #787878;
      font-size: 14px;
      font-weight: 500;
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms;
	  width:260px;
	}


	.header_area .search-area input:focus {
        outline: none !important;
		border-bottom:1px solid #787878;
	}



    .header_area .search-area button {
      position: absolute;
      z-index: 10;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 9px;
      background-color: transparent;
      border: none;
      color: #787878;
      font-size: 14px;
	}

	.header_area .search-area button:hover {
		cursor: pointer;
		font-size: 20px;

	}


.navbar_menu li
{
	display: inline-block;
}

.navbar_menu li a
{
	display: block;
	color: #1e1e27;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	padding: 25px;
	-webkit-transition: color 0.3s ease;
	-moz-transition: color 0.3s ease;
	-ms-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.navbar_menu li a:hover
{
	color: #b5aec4;
}

.navbar_user li
{
	display: inline-block;
	text-align: center;
}
.navbar_user li a
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 40px;
	height: 40px;
	color: #1e1e27;
	-webkit-transition: color 0.3s ease;
	-moz-transition: color 0.3s ease;
	-ms-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.navbar_user li div
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 40px;
	height: 40px;
	color: #1e1e27;
	-webkit-transition: color 0.3s ease;
	-moz-transition: color 0.3s ease;
	-ms-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.navbar_user li a:hover
{
	color: #b5aec4;
}
.checkout a
{
	background: #eceff6;
	border-radius: 50%;
}
.checkout_items
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -9px;
	left: 22px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fe4c50;
	font-size: 12px;
	color: #FFFFFF;
}

.border-box{
	border: 1px solid rgb(233, 233, 233);
}

/*********************************
3.5 Hamburger Menu
*********************************/

.hamburger_container
{
	display: none;
	margin-left: 40px;
}
.hamburger_container i
{
	font-size: 24px;
	color: #1e1e27;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.hamburger_container:hover i
{
	color: #b5aec4;
}
.hamburger_menu
{
	position: fixed;
	top: 0;
	right: -400px;
	width: 400px;
	height: 100vh;
	background: rgba(255,255,255,0.95);
	z-index: 10;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.hamburger_menu.active
{
	right: 0;
}
.fs_menu_overlay
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9;
	background: rgba(255,255,255,0);
	pointer-events: none;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.hamburger_close
{
	position: absolute;
	top: 26px;
	right: 10px;
	padding: 10px;
	z-index: 1;
}
.hamburger_close i
{
	font-size: 24px;
	color: #1e1e27;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.hamburger_close:hover i
{
	color: #b5aec4;
}
.hamburger_menu_content
{
	padding-top: 100px;
	width: 100%;
	height: 100%;
	padding-right: 20px;
}
.menu_item
{
	display: block;
	position: relative;
	border-bottom: solid 1px #b5aec4;
	vertical-align: middle;
}
.menu_item > a
{
	display: block;
	color: #1e1e27;
	font-weight: 500;
	height: 50px;
	line-height: 50px;
	font-size: 14px;
	text-transform: uppercase;
}
.menu_item > a:hover
{
	color: #b5aec4;
}
.menu_item > a > i
{
	margin-left: 8px;
}
.menu_item.active .menu_selection
{
	display: block;
	visibility: visible;
	opacity: 1;
}
.menu_selection
{
	margin: 0;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	z-index: 1;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.menu_selection li
{
	padding-left: 10px;
	padding-right: 10px;
	line-height: 50px;
}
.menu_selection li a
{
	display: block;
	color: #232530;
	border-bottom: solid 1px #dddddd;
	font-size: 13px;
	text-transform: uppercase;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: all 0.3s ease;
}
.menu_selection li a:hover
{
	color: #b5aec4;
}
.menu_selection li:last-child a
{
	border-bottom: none;
}

/*********************************
3.6 Logo
*********************************/

.logo_container
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 15px;
	margin-top: 20px;
}
.logo_container a
{
	font-size: 24px;
	color: #1e1e27;
	font-weight: 700;
	text-transform: uppercase;
}
.logo_container a span
{
	color: #fe4c50;
}

.logo_container a img
{
	margin-top: -28px;
	width: 140px;
	height:120px;
}

/*********************************
3.7 Main Slider
*********************************/

.main_slider
{
	width: 100%;
	height: 770px !important;
	background-repeat: no-repeat !important;
	margin-top: 150px;
	background-color: #d0cece !important;
}

/* .main_slider_image{
	display: block;
	max-width: 100%;
	height: auto;
	background-image: url("/assets/images/atol-main-slider.png");
} */

.main_slider_content
{
	width: 60%;
	margin-top: -26px;
    /* margin-left: -112px; */
}
.main_slider_content h6
{
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 29px;
	margin-left: 8px;
}
.main_slider_content h1
{
	font-weight: 400;
	font-size: 60px;
}
.red_button
{
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 40px;
	background: #fe4c50;
	border-radius: 3px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.red_button:hover
{
	background: #FE7C7F !important;
}
.red_button a
{
	display: block;
	color: #FFFFFF;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 40px;
	width: 100%;
}
.shop_now_button
{
	width: 140px;
	margin-top: 32px;
}

/*********************************
4. Banner
*********************************/

.banner
{
	width: 100%;
	margin-top: 30px;
}
.banner_item
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 265px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.banner_category
{
	height: 50px;
	background: #FFFFFF;
	min-width: 180px;
	padding-left: 25px;
	padding-right: 25px;
}
.banner_category a
{
	display: block;
	color: #1e1e27;
	text-transform: uppercase;
	font-size: 22px;
	font-weight: 600;
	text-align: center;
	line-height: 50px;
	width: 100%;
	-webkit-transition: color 0.3s ease;
	-moz-transition: color 0.3s ease;
	-ms-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}
.banner_category a:hover
{
	color: #b5aec4;
}

/*********************************
5. New Arrivals
*********************************/

.new_arrivals
{
	width: 100%;
}
.new_arrivals_title
{
	margin-top: 74px;
}

/*********************************
5.1 New Arrivals Sorting
*********************************/

.new_arrivals_sorting
{
	display: inline-block;
	border-radius: 3px;
	margin-top: 59px;
	overflow: visible;
}
.grid_sorting_button
{
	height: 40px;
	min-width: 102px;
	padding-left: 25px;
	padding-right: 25px;
	cursor: pointer;
	border: solid 1px #ebebeb;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	background: #FFFFFF;
	border-radius: 3px;
	margin: 0;
	float: left;
	margin-left: -1px;
}
.grid_sorting_button:first-child
{
	margin-left: 0px;
}
.grid_sorting_button:last-child
{

}
.grid_sorting_button.active:hover
{
	background: #FE7C7F !important;
}
.grid_sorting_button.active
{
	color: #FFFFFF;
	background: #fe4c50;
}

/*********************************
5.2 New Arrivals Products Grid
*********************************/

.product-grid
{
	width: 100%;
	margin-top: 57px;
}
.product-item
{
	width: 20%;
	height: 380px;
	cursor: pointer;
}
.product-item::after
{
	display: block;
	position: absolute;
	top: 0;
	left: -1px;
	width: calc(100% + 1px);
	height: 100%;
	pointer-events: none;
	content: '';
	border: solid 2px rgba(235,235,235,0);
	border-radius: 3px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.product-item:hover::after
{
	box-shadow: 0 25px 29px rgba(63, 78, 100, 0.15);
	border: solid 2px rgba(235,235,235,1);
}
.product
{
	width: 100%;
	height: 340px;
	border-right: solid 1px #e9e9e9;
}
.product_image
{
	width: 100%;
}
.product_image img
{
	width: 100%;
}
.product_info
{
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}
.product_name
{
	margin-top: 27px;
}
.product_name a
{
	color: #1e1e27;
	line-height: 20px;
}
.product_name:hover a
{
	color: #b5aec4;
}
.discount .product_info .product_price
{
	font-size: 14px;
}
.product_price
{
	font-size: 16px;
	color: #fe4c50;
	font-weight: 600;
}
.product_price span
{
	font-size: 12px;
	margin-left: 10px;
	color: #b5aec4;
	text-decoration: line-through;
}
.product_bubble
{
	position: absolute;
	top: 15px;
	width: 50px;
	height: 22px;
	border-radius: 3px;
	text-transform: uppercase;
}
.product_bubble span
{
	font-size: 12px;
	font-weight: 600;
	color: #FFFFFF;
}
.product_bubble_right::after
{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 6px;
	right: auto;
	top: auto;
	bottom: -6px;
	border: 6px solid;
}
.product_bubble_left::after
{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: auto;
	right: 6px;
	bottom: -6px;
	border: 6px solid;
}
.product_bubble_red::after
{
	border-color: transparent transparent transparent #fe4c50;
}
.product_bubble_left::after
{
	border-color: #51a042 #51a042 transparent transparent;
}
.product_bubble_red
{
	background: #fe4c50;
}
.product_bubble_green
{
	background: #51a042;
}
.product_bubble_left
{
	left: 15px;
}
.product_bubble_right
{
	right: 15px;
}
.add_to_cart_button
{
	width: 100%;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.add_to_cart_button a
{
	font-size: 12px;
	font-weight: 600;
}
.product-item:hover .add_to_cart_button
{
	visibility: visible;
	opacity: 1;
}
.favorite
{
	position: absolute;
	top: 15px;
	right: 15px;
	color: #b9b4c7;
	width: 17px;
	height: 17px;
	visibility: hidden;
	opacity: 0;
}
.favorite_left
{
	left: 15px;
}
.favorite.active
{
	visibility: visible;
	opacity: 1;
}
.product-item:hover .favorite
{
	visibility: visible;
	opacity: 1;
}
.favorite.active::after
{
	font-family: 'FontAwesome';
	content:'\f004';
	color: #fe4c50;
}
.favorite::after
{
	font-family: 'FontAwesome';
	content:'\f08a';
}
.favorite:hover::after
{
	color: #fe4c50;
}

/*********************************
6. Deal of the week
*********************************/

.deal_ofthe_week
{
	width: 100%;
	margin-top: 41px;
	background: #f2f2f2;
}
.deal_ofthe_week_img
{
	height: 540px;
	/* margin-left: -234px; */
}
.deal_ofthe_week_img img
{
	height: 100%;
}
.deal_ofthe_week_content
{
	height: 100%;
}
.deal_ofthe_week_col{
	min-height: 270px;
}
.section_title
{
	display: inline-block;
	text-align: center;
}
.section_title h2
{
	display: inline-block;
}
.section_title::after
{
	display: block;
	position: absolute;
	top: calc(100% + 13px);
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 60px;
	height: 5px;
	background: #fe4c50;
	content: '';
}
.timer
{
	margin-top: 66px;
}
.timer li
{
	display: inline-block;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background: #FFFFFF;
	margin-right: 10px;
}
.timer li:last-child
{
	margin-right: 0px;
}
.timer_num
{
	font-size: 48px;
	font-weight: 600;
	color: #fe4c50;
	margin-top: 10px;
}
.timer_unit
{
	margin-top: 12px;
	font-size: 16px;
	font-weight: 500;
	color: #51545f;
}
.deal_ofthe_week_button
{
	width: 140px;
	background: #1e1e27;
	margin-top: 52px;
}
.deal_ofthe_week_button:hover
{
	background: #2b2b34 !important;
}

/*********************************
7. Best Sellers
*********************************/

.product_slider_container
{
	width: 100%;
	height: 340px;
	margin-top: 73px;
}
.product_slider_container::after
{
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 1px;
	height: 100%;
	background: #FFFFFF;
	content: '';
	z-index: 1;
}
.product_slider
{
	overflow: visible !important;
}
.product_slider_item .product-item
{
	height: 340px;
}
.product_slider_item
{
	width: 20%;
}
.product_slider_item .product-item
{
	width: 100% !important;
}

/*********************************
7.1 Slider Nav
*********************************/

.product_slider_nav
{
	position: absolute;
	width: 30px;
	height: 70px;
	background: #d3d3d6;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 2;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.product_slider_container:hover .product_slider_nav
{
	visibility: visible;
	opacity: 1;
}
.product_slider_nav i
{
	color: #FFFFFF;
	font-size: 12px;
}
.product_slider_nav_left
{
	top: 50%;
	left: 0;
	visibility: visible;
    opacity: 1;
}
.product_slider_nav_right
{
	top: 50%;
	right: 0;
	visibility: visible;
    opacity: 1;
}

/*********************************
8. Benefit
*********************************/

.benefit
{
	margin-top: 74px;
}
.benefit_row
{
	padding-left: 15px;
	padding-right: 15px;
}
.benefit_col
{
	padding-left: 0px;
	padding-right: 0px;
}
.benefit_item
{
	height: 100px;
	background: #f3f3f3;
	border-right: solid 1px #FFFFFF;
	padding-left: 25px;
}
.benefit_col:last-child .benefit_item
{
	border-right: none;
}
.benefit_icon i
{
	font-size: 30px;
	color: #fe4c50;
}
.benefit_content
{
	padding-left: 22px;
}
.benefit_content h6
{
	text-transform: uppercase;
	line-height: 18px;
	font-weight: 500;
	margin-bottom: 0px;
}
.benefit_content p
{
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 0px;
	color: #51545f;
}

/*********************************
9. Blogs
*********************************/

.blogs
{
	margin-top: 72px;
}
.blogs_container
{
	margin-top: 53px;
}
.blog_item
{
	height: 255px;
	width: 100%;
}
.blog_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.blog_content
{
	position: absolute;
	top: 30px;
	left: 30px;
	width: calc(100% - 60px);
	height: calc(100% - 60px);
	background: rgba(255,255,255,0.9);
	padding-left: 10px;
	padding-right: 10px;
	visibility: hidden;
	opacity: 0;
	transform-origin: center center;
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	-ms-transform: scale(0.7);
	-o-transform: scale(0.7);
	transform: scale(0.7);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.blog_item:hover .blog_content
{
	visibility: visible;
	opacity: 1;
	transform: scale(1);
}
.blog_title
{
	font-weight: 500;
	line-height: 1.25;
	margin-bottom: 0px;
}
.blog_meta
{
	font-size: 12px;
	line-height: 30px;
	font-weight: 400;
	text-transform: uppercase;
	color: #51545f;
	margin-bottom: 0px;
	margin-top: 3px;
	letter-spacing: 1px;
}
.blog_more
{
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
	color: #fe4c50;
	border-bottom: solid 1px #fe4c50;
	margin-top: 12px;
}
.blog_more:hover
{
	color: #FE7C7F;
}

/*********************************
10. Newsletter
*********************************/

.newsletter
{
	width: 100%;
	background: #f2f2f2;
	margin-top: 81px;
}
.newsletter_text
{
	height: 120px;
}
.newsletter_text h4
{
	margin-bottom: 0px;
	line-height: 24px;
}
.newsletter_text p
{
	margin-bottom: 0px;
	line-height: 1.7;
	color: #51545f;
}
.newsletter_form
{
	height: 120px;
}
#newsletter_email
{
	width: 300px;
	height: 46px;
	background: #FFFFFF;
	border: none;
	padding-left: 20px;
}
#newsletter_email:focus
{
	border: solid 1px #7f7f7f !important;
	box-shadow: none !important;
}
:focus
{
	outline: none !important;
}
#newsletter_email::-webkit-input-placeholder
{
	font-size: 14px !important;
	font-weight: 400;
	color: #b9b4c7 !important;
}
#newsletter_email:-moz-placeholder /* older Firefox*/
{
	font-size: 14px !important;
	font-weight: 400;
	color: #b9b4c7 !important;
}
#newsletter_email::-moz-placeholder /* Firefox 19+ */
{
	font-size: 14px !important;
	font-weight: 400;
	color: #b9b4c7 !important;
}
#newsletter_email:-ms-input-placeholder
{
	font-size: 14px !important;
	font-weight: 400;
	color: #b9b4c7 !important;
}
#newsletter_email::input-placeholder
{
	font-size: 14px !important;
	font-weight: 400;
	color: #b9b4c7 !important;
}
.newsletter_submit_btn
{
	width: 160px;
	height: 46px;
	border: none;
	background: #fe4c50;
	color: #FFFFFF;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	cursor: pointer;
}
.newsletter_submit_btn:hover
{
	background: #FE7C7F;
}

/*********************************
11. Footer
*********************************/

#footer {
	background: #F0F0E9;
	margin-top:83px;
  }

  .companyinfo {
	margin-top: 57px;
  }

  .companyinfo h2 {
	color: #B4B1AB;
	font-family: abel;
	font-size: 27px;
	text-transform: uppercase;
  }

  .companyinfo h2  span{
	color:#FE980F;
  }

  .companyinfo p {
	color: #B3B3AD;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	font-weight: 300;
  }

  .footer-top .col-sm-3{
	overflow: hidden;
  }

  .video-gallery {
	margin-top: 57px;
	position: inherit;
  }

  .video-gallery a img {
	height: 100%;
	width: 100%;
  }

  .iframe-img {
	position: relative;
	display: block;
	height: 61px;
	margin-bottom: 10px;
	border: 2px solid #CCCCC6;
	border-radius: 3px;
  }

  .overlay-icon {
	position: absolute;
	top: 0;
	width: 100%;
	height: 61px;
	background: #FE980F;
	border-radius: 3px;
	color: #FFF;
	font-size: 20px;
	line-height: 0;
	display: block;
	opacity: 0;
	 -webkit-transition: 300ms;
	-moz-transition: 300ms;
	  -o-transition: 300ms;
	  transition: 300ms;
  }
  .text-center{
	  text-align: center;
  }
  .overlay-icon i {
	position: relative;
	top: 50%;
	margin-top: -20px;
  }

  .video-gallery a:hover .overlay-icon{
	opacity: 1;
  }

  .video-gallery p {
	color: #8C8C88;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	font-weight: 500;
	margin-bottom:0px;
  }

  .video-gallery  h2 {
	color: #8c8c88;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	font-weight: 300;
	text-transform:uppercase;
	margin-top:0px;
  }


  .address {
	margin-top: 30px;
	position: relative;
	overflow: hidden;
  }
  .address  img {
	  width:100%;
  }


  .footer-widget {
	margin-bottom: 68px;
  }

  .footer-widget .container {
	padding-top: 55px;
  }

  .footer-phone{
	background-color:#f49021;
    border-radius: 15px;
  }

  .single-widget h2 {
	color: #666663;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 22px;
	text-transform: uppercase;
  }

  .single-widget h2 i{
	  margin-right:15px;
  }

  .single-widget ul li a{
	  color:#989898;
	  font-family: 'Poppins', sans-serif;
	  font-size: 14px;
	  font-weight: 500;
	  padding: 5px 0;
	  -webkit-font-smoothing: antialiased;
  }

  .single-widget{
	padding-bottom: 30px;
}

  .single-widget ul li a i {
	margin-right: 18px;
  }

  .single-widget ul li a:hover{
	  background:none;
	  color:#FE980F;
  }

  .single-widget h6{
	color:#989898 !important;
	font-family: 'Poppins', sans-serif !important;
	font-size: 14px;
	font-weight: 500;
	padding: 5px 0;
	-webkit-font-smoothing: antialiased;
}


  .searchform input {
	border: 1px solid #DDDDDD;
	color: #CCCCC6;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	margin-top: 0;
	outline: medium none;
	padding: 7px;
	width: 212px;
  }


  .searchform button {
	background: #FE980F;
	border: medium none;
	border-radius: 0;
	margin-left: -5px;
	margin-top: -3px;
	padding: 7px 17px;
  }

  .searchform button i {
	color: #FFFFFF;
	font-size: 20px;
  }

  .searchform  button:hover,
  .searchform  button:focus{
	  background-color:#FE980F;
  }

  .searchform p {
	color: #8C8C88;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 300;
	margin-top: 25px;
  }

  .footer-bottom {
	background: #D6D6D0;
	padding-top: 10px;
  }

  .footer-bottom p {
	color: #363432;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	margin-left: 15px;
  }

  .footer-bottom p span a {
	color: #FE980F;
	font-style: italic;
	text-decoration: underline;
  }


  /*************************
  ******* Home ******
  **************************/


  #slider {
	padding-bottom: 45px;
  }

  .carousel-indicators li {
	background: #C4C4BE;
  }

  .carousel-indicators li.active {
		background: #FE980F;
  }

  .item {
	padding-left: 100px;
  }


  .pricing {
	position: absolute;
	right: 40%;
	top: 52%;
  }

  .girl {
	margin-left: 0;
  }

  .item h1 {
	color: #B4B1AB;
	font-family: abel;
	font-size: 48px;
	margin-top: 115px;
  }

  .item h1 span {
	  color:#FE980F;
  }

  .item h2 {
	color: #363432;
	font-family: 'Roboto', sans-serif;
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 22px;
	margin-top: 10px;
  }

  .item  p {
	  color:#363432;
	  font-size:16px;
	  font-weight:300;
	  font-family: 'Roboto', sans-serif;
  }

  .get {
	background: #FE980F;
	border: 0 none;
	border-radius: 0;
	color: #FFFFFF;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 300;
	margin-top: 23px;
  }


  .item button:hover {
	background: #FE980F;
  }

  .control-carousel {
	position: absolute;
	top: 50%;
	font-size: 60px;
	color: #C2C2C1;
  }

  .control-carousel:hover{
	color: #FE980F ;
  }

  .right {
	right: 0;
  }

  .category-products {
	border: 1px solid #F7F7F0;
	margin-bottom: 35px;
	padding-bottom: 20px;
	padding-top: 15px;
  }
  .left-sidebar h2, .brands_products h2 {
	color: #FE980F;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 700;
	margin: 0 auto 30px;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	z-index:3;
  }

  .left-sidebar h2:after, h2.title:after{
	  content: " ";
	  position: absolute;
	  border: 1px solid #f5f5f5;
	  bottom:8px;
	  left: 0;
	  width: 100%;
	  height: 0;
	  z-index: -2;
  }

  .left-sidebar h2:before{
	  content: " ";
	  position: absolute;
	  background: #fff;
	  bottom: -6px;
	  width: 130px;
	  height: 30px;
	  z-index: -1;
	  left: 50%;
	  margin-left: -65px;
  }

  h2.title:before{
	  content: " ";
	  position: absolute;
	  background: #fff;
	  bottom: -6px;
	  width: 220px;
	  height: 30px;
	  z-index: -1;
	  left: 50%;
	  margin-left: -110px;
  }

  .category-products .panel {
	  background-color: #FFFFFF;
	  border: 0px;
	  border-radius: 0px;
	  box-shadow:none;
	  margin-bottom: 0px;
  }

  .category-products .panel-default .panel-heading {
	background-color: #FFFFFF;
	border: 0 none;
	color: #FFFFFF;
	padding: 5px 20px;
  }

  .category-products .panel-default .panel-heading .panel-title a {
	color: #696763;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	text-decoration: none;
	text-transform: uppercase;
  }

  .panel-group .panel-heading + .panel-collapse .panel-body {
	border-top: 0 none;
  }

  .category-products .badge {
	background:none;
	border-radius: 10px;
	color: #696763;
	display: inline-block;
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	min-width: 10px;
	padding: 3px 7px;
	text-align: center;
	vertical-align: baseline;
	white-space: nowrap;
  }

  .panel-body ul{
	padding-left: 20px;
  }


  .panel-body ul li a {
	color: #696763;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	text-transform: uppercase;
  }

  .brands-name {
	border: 1px solid #F7F7F0;
	padding-bottom: 20px;
	padding-top: 15px;
  }


  .brands-name .nav-stacked li a {
	background-color: #FFFFFF;
	color: #696763;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	padding: 5px 25px;
	text-decoration: none;
	text-transform: uppercase;
  }

  .brands-name .nav-stacked li a:hover{
	background-color: #fff;
	color: #696763;
  }

  .shipping {
	background-color: #F2F2F2;
	margin-top: 40px;
	overflow: hidden;
	padding-top: 20px;
	position: relative;
  }


  .price-range{
	margin-top:30px;
  }

  .well {
	background-color: #FFFFFF;
	border: 1px solid #F7F7F0;
	border-radius: 4px;
	box-shadow: none;
	margin-bottom: 20px;
	min-height: 20px;
	padding: 35px;
  }


  .tooltip-inner {
	background-color: #FE980F;
	border-radius: 4px;
	color: #FFFFFF;
	max-width: 200px;
	padding: 3px 8px;
	text-align: center;
	text-decoration: none;
  }

  .tooltip.top .tooltip-arrow {
	border-top-color: #FE980F;
	border-width: 5px 5px 0;
	bottom: 0;
	left: 50%;
	margin-left: -5px;
  }


  .padding-right {
		padding-right: 0;
  }


  .features_items{
	  overflow:hidden;
  }


  h2.title {
	color: #FE980F;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 700;
	margin: 0 15px;
	text-transform: uppercase;
	margin-bottom: 30px;
	position: relative;
  }

  .product-image-wrapper{
	  border:1px solid #F7F7F5;
	  overflow: hidden;
	  margin-bottom:30px;
  }

  .single-products {
	position: relative;
  }

  .new, .sale {
	position: absolute;
	top: 0;
	right: 0;
  }

  .productinfo h2{
	  color: #FE980F;
	  font-family: 'Roboto', sans-serif;
	  font-size: 24px;
	  font-weight: 700;
  }
  .product-overlay h2{
	  color: #fff;
	  font-family: 'Roboto', sans-serif;
	  font-size: 24px;
	  font-weight: 700;
  }


  .productinfo p{
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #696763;
  }

  .productinfo img{
	width: 100%;
  }

  .productinfo{
   position:relative;
  }

  .product-overlay {
	background:#FE980F;
	top: 0;
	display: none;
	height: 0;
	position: absolute;
	transition: height 500ms ease 0s;
	width: 100%;
	display: block;
	opacity:;
  }

  .single-products:hover .product-overlay {
	display:block;
	height:100%;
  }


  .product-overlay .overlay-content {
	bottom: 0;
	position: absolute;
	bottom: 0;
	text-align: center;
	width: 100%;
  }

  .product-overlay .add-to-cart {
	background:#fff;
	border: 0 none;
	border-radius: 0;
	color: #FE980F;
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	margin-bottom: 25px;
  }

  .product-overlay .add-to-cart:hover {
	background:#fff;
	color: #FE980F;
  }


  .product-overlay p{
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #fff;
  }



  .add-to-cart {
	background:#F5F5ED;
	border: 0 none;
	border-radius: 0;
	color: #696763;
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	margin-bottom: 25px;
  }

  .add-to-cart:hover {
	background: #FE980F;
	border: 0 none;
	border-radius: 0;
	color: #FFFFFF;
  }

  .add-to{
	margin-bottom: 10px;
  }

  .add-to-cart i{
	  margin-right:5px;
  }

  .add-to-cart:hover {
	background: #FE980F;
	color: #FFFFFF;
  }

  .choose {
	border-top: 1px solid #F7F7F0;
  }

  .choose ul li a {
	color: #B3AFA8;
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
	padding-left: 0;
	padding-right: 0;
  }

  .choose ul li a i{
	  margin-right:5px;
  }

  .choose ul li a:hover{
	  background:none;
	  color:#FE980F;
  }

  .category-tab {
	overflow: hidden;
  }

  .category-tab ul {
	background: #40403E;
	border-bottom: 1px solid #FE980F;
	list-style: none outside none;
	margin: 0 0 30px;
	padding: 0;
	width: 100%;
  }

  .category-tab ul li a {
	border: 0 none;
	border-radius: 0;
	color: #B3AFA8;
	display: block;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	text-transform: uppercase;
  }

  .category-tab ul  li  a:hover{
	  background:#FE980F;
	  color:#fff;
  }

  .nav-tabs  li.active  a, .nav-tabs  li.active  a:hover, .nav-tabs  li.active  a:focus {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background-color: #FE980F;
	border:0px;
	color: #FFFFFF;
	cursor: default;
	margin-right:0;
	margin-left:0;
  }

  .nav-tabs  li  a {
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 4px 4px 0 0;
	line-height: 1.42857;
	margin-right:0;
  }

  .recommended_items {
	overflow: hidden;
  }

  #recommended-item-carousel .carousel-inner .item {
	padding-left: 0;
  }

  .recommended-item-control {
	position: absolute;
	top: 41%;
  }

  .recommended-item-control i {
	background: none repeat scroll 0 0 #FE980F;
	color: #FFFFFF;
	font-size: 20px;
	padding: 4px 10px;
  }

  .recommended-item-control i:hover {
	background: #ccccc6;
  }

  .recommended_items  h2 {
  }

  .our_partners{
	overflow:hidden;
  }

  .our_partners ul {
	background: #F7F7F0;
	margin-bottom: 50px;
  }


  .our_partners ul li a:hover{
	  background:none;
  }

  .about-us-header h2{
	color: #666663;
	font-family: 'Roboto', sans-serif;
	font-size: 28px;
	font-weight: 500;
	margin-bottom: 22px;
  }
  .about-us-info{
	width: 100%;
	font-size: 16px;
  }
.margin-top-73{
	margin-top: 73px;
	}
  .margin-top-25{
	  margin-top: 25px;
  }

  .margin-bottom-35{
	margin-bottom: 35px;
}

.margin-bottom-75{
	margin-bottom: 75px;
}
.padding-bottom-0{
	padding-bottom: 0px !important;
}

.fa-1x {
  font-size: 1.5rem;
}
.navbar-toggler.toggler-example {
  cursor: pointer;
}

.image-card-cover{
	object-fit: contain !important;
}
#products-root{
	margin-top: 15px
}

.padding-top-30{
	padding-top: 30px
}

.padding-10{
	padding:10px
}
